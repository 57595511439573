// modules
import React from 'react'
import { graphql } from 'gatsby'
// components
import SEO from '../components/shared/SEO/SEO'

// definitions
function PrivacyPage({ data, location }) {
  const {
    file: {
      childMarkdownRemark: { frontmatter, html },
    },
  } = data
  const pageRoute = location.pathname
  const pageTitle = frontmatter.title
  const pageContent = html

  return (
    <>
      <SEO title={pageTitle} route={pageRoute} />
      <h1>{pageTitle}</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: pageContent,
        }}
      />
    </>
  )
}

// exports
export default PrivacyPage

// query
export const privacyPageQuery = graphql`
  query {
    file(
      sourceInstanceName: { eq: "content" }
      relativePath: { eq: "pages/privacy.md" }
    ) {
      childMarkdownRemark {
        frontmatter {
          title
        }
        html
      }
    }
  }
`
